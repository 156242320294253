import { useNavigate, useLocation } from 'react-router-dom';

function Header() {
    const navigate = useNavigate();
    const location = useLocation();

    const isActive = (pathname) => location.pathname === pathname;

    return (
        <header className="bg-black text-white">
            <div className="max-w-6xl mx-auto px-4 py-1 flex justify-between items-center">
                
                {/*<div className="text-lg font-bold text-white md:block hidden">Coffee Powered Games</div>*/}
                {/*todo please fix this*/}
                <div className="text-lg font-bold text-white md:block hidden">                    </div>
                <nav>
                    <button onClick={() => navigate('/')}
                            className={`bg-black ${isActive('/') ? 'text-white focus:outline-none' : 'text-gray-400'} hover:text-white font-bold py-2 px-4 rounded mr-2`}>Home
                    </button>
                    {/*<button onClick={() => navigate('/about')} className={`bg-black ${isActive('/about') ? 'text-white' : 'text-gray-400'} hover:text-white font-bold py-2 px-4 rounded mr-2`}>About</button>*/}
                    {/*<button onClick={() => navigate('/services')} className={`bg-black ${isActive('/services') ? 'text-white' : 'text-gray-400'} hover:text-white font-bold py-2 px-4 rounded mr-2`}>Services</button>*/}
                    {/*<button onClick={() => navigate('/blog')} className={`bg-black ${isActive('/blog') ? 'text-white' : 'text-gray-400'} hover:text-white font-bold py-2 px-4 rounded mr-2`}>Blog</button>*/}
                    <button onClick={() => navigate('/contact')}
                            className={`bg-black ${isActive('/contact') ? 'text-white focus:outline-none' : 'text-gray-400'} hover:text-white font-bold py-2 px-4 rounded`}>Contact
                    </button>
                </nav>
            </div>
        </header>
    );
}

export default Header;