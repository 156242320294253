import React, {useState, createRef} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import email from './cpg_email.png';


function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        access_key: '89414393-9178-4bfa-a2c9-1da5b2a53786'
    });
    const [isSubmitted, setIsSubmitted] = useState(false); // New state to track form submission
    let [recaptcha, setRecaptcha] = useState('');
    const recaptchaRef = createRef();

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleRecaptcha = (value) => {
        setFormData({
            ...formData
        });

        setRecaptcha(value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if reCAPTCHA test has been completed
        if (!recaptcha) {
            alert('Please complete the reCAPTCHA test before submitting the form.');
            return;
        }

        try {
            const response = await fetch('https://api.web3forms.com/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                console.log('Form successfully submitted');
                //clear the form
                setFormData({
                    name: '',
                    email: '',
                    message: '',
                    access_key: '89414393-9178-4bfa-a2c9-1da5b2a53786'
                });
                recaptchaRef.current.reset();
                setIsSubmitted(true);
                setTimeout(() => setIsSubmitted(false), 5000);//hide message after 5 seconds
                // alert('Form successfully submitted');
            } else {
                //show response status text
                const text = await response.text();
                alert('Form submission failed:', text);
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Error: ' + error.message);
        }
    };

    return (
        <div>
            <img src={email} alt="email" className="mx-auto w-auto h-auto"/>

            <form onSubmit={handleSubmit} className="max-w-lg mx-auto p-4">
                <div className="mb-4">
                    <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">Email:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="message" className="block text-gray-700 text-sm font-bold mb-2">Message:</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
                    />
                </div>
                <input type="hidden" name="access_key" value="89414393-9178-4bfa-a2c9-1da5b2a53786"/>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    // sitekey="6LeswIQpAAAAANwtZyFb1HfFDshIyEWkSr7fkK3f" //v1 - key
                    sitekey="6LcDd4UpAAAAAP2v1es_bEscuNgR3FDuNniBbhKN" //v2 - key
                    // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"//testkey stackovereflow
                    onChange={handleRecaptcha}
                    onExpired={() => handleRecaptcha('')}
                    className="flex justify-center items-center mb-4"
                />
                <div className="flex justify-center items-center">
                    <button type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Submit
                    </button>
                </div>
            </form>
            {isSubmitted && (
                <div className="max-w-lg mx-auto p-4 text-center mt-4 flex justify-center items-center">
                    <p className="text-lg text-green-500 font-bold">Message sent successfully.</p>
                </div>
            )}
        </div>
    );
}

export default ContactForm;