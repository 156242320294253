import React from 'react';
import {useParams} from 'react-router-dom';

/*
* Content Centering: The component now uses flex, flex-col, items-center, and justify-center classes to center the content vertically and horizontally on the page.
* Code Formatting: The use of pre and bg-gray-100 p-4 rounded-lg classes for code blocks creates a visually distinct area for code examples, enhancing readability. The text-green-700 class further improves the appearance by adding color to the text.
* Layout and Design: The max-w-4xl w-full classes ensure that the content area is responsive and maintains an optimal width, while rounded-lg and shadow-lg classes enhance the overall design with rounded corners and a subtle shadow.
* */

function BlogPostDetail() {
    let {id} = useParams(); // Retrieve the post ID from URL parameters

    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-4xl w-full">
                <h1 className="text-3xl font-bold mb-4">Pointers and References in C++</h1>
                <p className="text-sm text-gray-500 mb-4">Published on some date</p>
                <p className="text-gray-700 mb-4">Understanding pointers and references in C++ is crucial for managing
                    memory and creating efficient programs. Here's a quick overview:</p>

                <h2 className="text-2xl font-semibold mb-2">Pointers</h2>
                <p className="text-gray-700 mb-4">A pointer is a variable that stores the memory address of another
                    variable.</p>
                <div className="bg-gray-100 p-4 rounded-lg">
                    <pre className="text-green-700">
{`int main() {
    int var = 20;
    int *ptr = &var;
    std::cout << "Value of var: " << var << std::endl;
    std::cout << "Address stored in ptr: " << ptr << std::endl;
    std::cout << "Content of the address: " << *ptr << std::endl;
    return 0;
}`}
                    </pre>
                </div>

                <h2 className="text-2xl font-semibold mb-2 mt-4">References</h2>
                <p className="text-gray-700 mb-4">A reference is an alias for another variable. It is created using the
                    & symbol.</p>
                <div className="bg-gray-100 p-4 rounded-lg">
                    <pre className="text-green-700">
{`int main() {
    int var = 30;
    int &ref = var;
    ref = 20;
    std::cout << "Value of var: " << var << std::endl;
    return 0;
}`}
                    </pre>
                </div>
            </div>
        </div>
    );
}

export default BlogPostDetail;
