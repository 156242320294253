import React from 'react';
import unityLogo from './unity-logo.svg';
import csharpLogo from './csharp-logo.png';
import unrealLogo from './unreal-logo.png';
import cppLogo from './cpp-logo.png';
import cloudLogo from './cloud-logo-thick.png';
import goLogo from './go-logo.svg';
import groovyLogo from './groovy-logo.png';

function LandingPage({}) {
    return (
        <div className="bg-white p-4 flex flex-col items-center mt-4">
            <div className="mb-4 flex-shrink-0">
                <p className="text-center text-lg md:text-base lg:text-lg font-bold">SOFTWARE DEVELOPMENT</p>
            </div>
            <div className="flex flex-col items-center mt-4">
                <div className="mb-4">
                    <img src={unityLogo} alt="Unity Logo" className="mx-auto rounded w-full md:w-auto h-32 mb-4"/>
                </div>
                <div className="mb-4">
                    <img src={csharpLogo} alt="CSharp Logo" className="mx-auto rounded w-full md:w-auto h-32 mb-4"/>
                </div>
                <div className="mb-4">
                    <img src={unrealLogo} alt="Unreal Logo" className="mx-auto rounded w-full md:w-auto h-32 mb-4"/>
                </div>
                <div className="mb-4">
                    <img src={cppLogo} alt="Cpp Logo" className="mx-auto rounded w-full md:w-auto h-32 mb-4"/>
                </div>
                <div className="mb-4">
                    <img src={cloudLogo} alt="Cloud Logo" className="mx-auto rounded w-full md:w-auto h-32 mb-4"/>
                </div>
                <div className="mb-4">
                    <img src={goLogo} alt="GO Logo" className="mx-auto rounded w-full md:w-auto h-32 mb-4"/>
                </div>
                <div className="mb-4">
                    <img src={groovyLogo} alt="Groovy Logo" className="mx-auto rounded w-full md:w-auto h-32 mb-4"/>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;